import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {ModalCrearConversacionComponent} from '../modal-crear-conversacion/modal-crear-conversacion.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {Title} from "@angular/platform-browser";
import {CarpetasComponent} from "../carpetas/carpetas.component";
import {ModalCrearCarpetaComponent} from "../modal-crear-carpeta/modal-crear-carpeta.component";
import {Carpeta} from '../../interfaces/carpeta';
import {ConversacionListado} from '../../interfaces/conversacion';
import {ModalService} from '../modal/modal.service';
import {ListarModelosLlmActivosService} from "../../services/listar-modelos-llm-activos.service";

export interface BuscarConversacionesFG {
  asunto: string | null;
}

@Component({
  selector: 'app-conversaciones',
  templateUrl: './conversaciones.component.html',
  styleUrls: ['./conversaciones.component.css']
})
export class ConversacionesComponent {
  @ViewChild('carpetasComponent') carpetasComponent = {} as CarpetasComponent;
  @ViewChild('modalCrearCarpeta') modalCrearCarpetaComponent = {} as ModalCrearCarpetaComponent;
  protected _esDispositivoMovil!: boolean;

  @Input()
  set esDispositivoMovil(esDispositivoMovil: boolean) {
    this._esDispositivoMovil = esDispositivoMovil;
  }

  @Input()
  public set carpetas(carpetas: Carpeta[]) {
    setTimeout(() => {
      this.carpetasComponent.carpetas = carpetas;
    }, 300)
  };

  @Input()
  public set conversaciones(conversaciones: any) {
    setTimeout(() => {
      this.carpetasComponent.conversacionesMes = conversaciones;
    }, 300)
  };

  public get carpetas(): Carpeta[] {
    return this.carpetasComponent.carpetas;
  }

  public get conversaciones(): ConversacionListado[] {
    return this.carpetasComponent.conversacionesMes;
  }

  public buscarConversacionesForm = {} as FormGroup;
  @Output() public accionRealizada = new EventEmitter<boolean>();
  @Output() public chatSeleccionado = new EventEmitter<boolean>();

  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private title: Title,
              private modalService: ModalService,
              private listarModelosLlmActivosService: ListarModelosLlmActivosService) {
    this.buildForm();
  }

  public abrirModalNuevoChat(): void {
    this.listarModelosLlmActivosService.__invoke().subscribe({
      next: (modelosActivos) => {
        this.accionRealizada.emit(true);
        const modalRef = this.modalService.loadComponent(ModalCrearConversacionComponent);
        this.modalService.setTitulo('Nueva conversación');
        modalRef.instance.modelosActivos = modelosActivos;
        this.modalService.onDidDismiss$.subscribe({
          next: async (result) => {
            if (result) {
              this.carpetasComponent.buscarConversaciones();
              this.carpetasComponent.buscarCarpetas();
              await this.router.navigate(['/chat/' + result.id], {queryParams: {carpetaId: result.carpetaId}});
              this.title.setTitle(result.asunto);
            }
          }
        });
      }
    });
  }

  public abrirModalNuevaCarpeta(): void {
    this.modalCrearCarpetaComponent.openModal();
    this.modalCrearCarpetaComponent.actionConfirmedSubject.subscribe({
      next: (result) => {
        if (result) {
          this.carpetasComponent.buscarCarpetas();
        }
      }
    });
  }

  private buildForm() {
    this.buscarConversacionesForm = this.formBuilder.group<BuscarConversacionesFG>({
      asunto: null
    });

    this.buscarConversacionesForm.valueChanges.pipe(debounceTime(300), distinctUntilChanged()).subscribe({
      next: () => {
        this.carpetasComponent.buscarConversaciones();
      }
    });
  }
}
