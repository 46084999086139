import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {PerfilConversacionListable} from '../interfaces/perfil-conversacion';

@Injectable({
  providedIn: 'root'
})
export class ObtenerPerfilesConversacionService {

  private readonly perfilesConversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.perfilesConversacionUrl = environment.apiUrl + '/shared/perfiles-conversacion/listar';
  }

  public __invoke(): Observable<PerfilConversacionListable[]> {
    return this.httpClient.get<PerfilConversacionListable[]>(this.perfilesConversacionUrl);
  }
}
