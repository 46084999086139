import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GrupoBotonesComponent} from './grupo-botones.component';


@NgModule({
  declarations: [
    GrupoBotonesComponent
  ],
  exports: [
    GrupoBotonesComponent
  ],
  imports: [
    CommonModule
  ]
})
export class GrupoBotonesModule { }
