import {Component} from '@angular/core';
import {ModalBaseComponent} from "../../../../infrastructure/modules/modal-base/modal-base.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {EditarCarpetaService} from "./editar-carpeta.service";
import {ValidationService} from "../../../../infrastructure/services/validation.service";
import {Carpeta} from "../../interfaces/carpeta";

export interface EditarCarpetaFG {
  descripcion: string | null;
}
@Component({
  selector: 'app-modal-editar-carpeta',
  templateUrl: './modal-editar-carpeta.component.html',
  styleUrls: ['./modal-editar-carpeta.component.css']
})
export class ModalEditarCarpetaComponent extends ModalBaseComponent{

  override identificadorModal: string = 'modal-editar-carpeta' + Math.random().toString(36).substring(7);
  public editarCarpetaForm = {} as FormGroup;
  public carpeta = {} as Carpeta;
  public loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private editarCarpetaService: EditarCarpetaService,
              private validationService: ValidationService) {
    super();
    this.buildForm();
  }

  private buildForm(): void {
    this.editarCarpetaForm = this.formBuilder.group<EditarCarpetaFG>({
      descripcion: null,
    });
  }

  editarCarpeta(): void {
    this.loading = true;
    this.editarCarpetaService.__invoke(this.carpeta.id, this.editarCarpetaForm.value).subscribe({
      next: (carpeta) => {
        this.buildForm();
        this.confirmAction(carpeta);
        this.loading = false;
      }, error: (errorResponse) => {
        this.loading = false;
        this.validationService.validateForm(this.editarCarpetaForm, errorResponse.error.errors);
      }
    })
  }

  public setCarpeta(carpeta: Carpeta): void {
    this.carpeta = carpeta;
    this.editarCarpetaForm.patchValue({
      descripcion: this.carpeta.descripcion,
    });
  }
}
