import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {Conversacion} from "../../interfaces/conversacion";

@Injectable({
  providedIn: 'root'
})
export class MoverConversacionService {
  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/mover/';
  }

  public __invoke(conversacionId:string, carpetaId: string|null): Observable<Conversacion> {
    return this.httpClient.put<Conversacion>(this.conversacionUrl + conversacionId, {carpetaId: carpetaId});
  }
}
