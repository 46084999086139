import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MarcarTooltipService {
  private readonly sharedUrl: string;

  constructor(private httpclient: HttpClient) {
    this.sharedUrl = environment.apiUrl + '/shared/tooltip/marcar';
  }

  public __invoke(): Observable<void> {
    return this.httpclient.put<void>(this.sharedUrl, {});
  }
}
