import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {Observable} from "rxjs";
import {Carpeta} from "../../interfaces/carpeta";
import {EditarCarpetaFG} from "./modal-editar-carpeta.component";

@Injectable({
  providedIn: 'root'
})
export class EditarCarpetaService {

  private readonly carpetaUrl: string;

  constructor(private httpClient: HttpClient) {
    this.carpetaUrl = environment.apiUrl + '/shared/carpeta/';
  }

  public __invoke(carpetaId: string, parametros: EditarCarpetaFG): Observable<Carpeta> {
    return this.httpClient.put<Carpeta>(this.carpetaUrl + carpetaId, parametros);
  }
}
