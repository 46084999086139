import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConversacionesComponent} from './conversaciones.component';
import {TranslateModule} from '@ngx-translate/core';
import {ModalCrearConversacionModule} from '../modal-crear-conversacion/modal-crear-conversacion.module';
import {ReactiveFormsModule} from '@angular/forms';
import {CarpetasModule} from "../carpetas/carpetas.module";
import {ModalCrearCarpetaModule} from "../modal-crear-carpeta/modal-crear-carpeta.module";

@NgModule({
    declarations: [
        ConversacionesComponent
    ],
    exports: [
        ConversacionesComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ModalCrearConversacionModule,
        ReactiveFormsModule,
        CarpetasModule,
        ModalCrearCarpetaModule
    ]
})
export class ConversacionesModule { }
