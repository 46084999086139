import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipComponent} from "./tooltip.component";


@NgModule({
  declarations: [
    TooltipComponent
  ],
  exports: [
    TooltipComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot()
  ]
})
export class TooltipModule { }
