import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent {
  private _referencias: string[] = [];
  protected _title: string = '';
  protected _mostrarBoton: boolean = false;
  protected _height: string = '';
  @Output() dismissed = new EventEmitter<boolean>();

  @Input() set text(text: string) {
    if (!text) return;
    this._referencias = text.split(' |---| ');
  }

  @Input() set title(title: string) {
    this._title = title;
  }

  @Input() set height(height: string) {
    this._height = height;
  }

  @Input() set mostrarBoton(mostrarBoton: boolean) {
    this._mostrarBoton = mostrarBoton;
  }

  get referencias(): string[] {
    return this._referencias;
  }

  dismiss() {
    this.dismissed.emit(true);
  }
}
