import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

import {Observable, throwError} from 'rxjs';
import {Router} from "@angular/router";

@Injectable()
export class CustomInterceptorInterceptor implements HttpInterceptor {

  constructor(private inj: Injector,
              private router: Router,) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const dupReq = req.clone();
    return next.handle(dupReq).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((error as HttpErrorResponse).status) {
            case 400:
            case 403:
            case 401:
            case 405:
              this.goToLogin()
              return throwError(error)
            default:
              return throwError(error);
          }
        } else {
          return throwError(() => error);
        }

      }));
  }


  private goToLogin(): void {
    sessionStorage.clear()
    localStorage.clear()
    this.router.navigate(['/login']);
  }

}
