import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {OptionModelo} from "../interfaces/option-item";

@Injectable({
  providedIn: 'root'
})
export class ListarModelosLlmActivosService {
  private readonly sharedUrl: string;

  constructor(private httpClient: HttpClient) {
    this.sharedUrl = environment.apiUrl + '/shared/configuracion-modelo-llm/listar';
  }

  public __invoke(): Observable<OptionModelo[]> {
    return this.httpClient.get<OptionModelo[]>(this.sharedUrl);
  }
}
