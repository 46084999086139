<div class="p-2 space-y-3 overflow-y-auto" [ngClass]="_height">
  <h5 class="text-lg font-bold text-gray-900 mb-2">{{ _title }}</h5>
  <div *ngFor="let ref of referencias; let i = index" class="last:border-b-0">
    <p class="text-gray-700 text-sm leading-relaxed">{{ ref }}</p>
    <hr *ngIf="referencias.length > 1 && i < referencias.length - 1" class="my-2 border-gray-300" />
  </div>
  <button (click)="dismiss()" *ngIf="_mostrarBoton"
    class="w-30 h-12 float-right hover:bg-indigo-100 text-indigo-600 group flex gap-x-1 rounded-md p-3 text-sm leading-6 font-semibold c-pointer border shadow-sm hover:border-indigo-500">
    {{ 'Entendido' | translate}}
  </button>
</div>
