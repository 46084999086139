import {Component, OnInit} from '@angular/core';
import {AccountInfo} from '@azure/msal-common';
import {ObtenerUsuarioService} from './obtener-usuario.service';
import {ObtenerImagenPerfilService} from './obtener-imagen-perfil.service';
import {MsalService} from '@azure/msal-angular';
import {ObtenerMenuService} from './obtener-menu.service';
import {ElementoMenu} from './elemento-menu';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {ObtenerImagenCorporativaService} from '../../../application/public/services/obtener-imagen-corporativa.service';
import {PanelLateralService} from '../../../application/shared/modules/panel-lateral/panel-lateral.service';
import {AyudaComponent} from '../../../domain/shared/ayuda/ayuda.component';
import {ObtenerAyudaService} from "./obtener-ayuda.service";
import {RutaAyuda} from './ruta-ayuda';
import {ObtenerTooltipService} from "./obtener-tooltip.service";
import {MarcarTooltipService} from "./marcar-tooltip.service";

@Component({
  selector: 'app-pantalla-con-menu',
  templateUrl: './pantalla-con-menu.component.html',
  styleUrls: ['./pantalla-con-menu.component.css']
})
export class PantallaConMenuComponent implements OnInit {

  public usuario!: AccountInfo | null;
  public imagenPerfil!: any;
  public elementosMenu = [] as ElementoMenu[];
  private menuAbierto = false;
  public logoCuadrado: string | null = null;
  public logoBase: string = 'assets/img/logo-lunia-blanco.svg';
  public soloLogoBase: boolean = false;
  public rutaActual: string = '';
  protected rutaWordpress: string | undefined;
  private rutasWordpress = [] as RutaAyuda[];
  protected mostrarTooltip: boolean = false;

  constructor(private obtenerUsuarioService: ObtenerUsuarioService,
              private obtenerImagenPerfilService: ObtenerImagenPerfilService,
              private msalService: MsalService,
              private obtenerMenuService: ObtenerMenuService,
              private sanitizer: DomSanitizer,
              private router: Router,
              private obtenerImagenCorporativaService: ObtenerImagenCorporativaService,
              private obtenerAyudaService: ObtenerAyudaService,
              private panelLateralService: PanelLateralService,
              private obtenerTooltipService: ObtenerTooltipService,
              private marcarTooltipService: MarcarTooltipService) {
    if (this.obtenerImagenCorporativaService.obtenerImagenCorporativa('logoCuadrado')) {
      this.logoCuadrado = this.obtenerImagenCorporativaService.obtenerImagenCorporativa('logoCuadrado');
    }
    if (this.obtenerImagenCorporativaService.obtenerImagenCorporativa('logoBase')) {
      this.logoBase = this.obtenerImagenCorporativaService.obtenerImagenCorporativa('logoBase');
    }
    this.soloLogoBase = this.logoCuadrado === null && this.logoBase !== null;
  }

  public ngOnInit(): void {
    this.obtenerMenu();
    this.usuario = this.obtenerUsuarioService.__invoke() as AccountInfo;
    this.getImagenPerfil();
    this.obtenerTooltip();
    this.obtenerRutasAyuda();
    this.router.events.subscribe((event) => {
      this.rutaActual = this.router.url;
    })
  }

  public getImagenPerfil(): void {
    if (this.usuario) {
      this.obtenerImagenPerfilService.__invoke(this.usuario!.localAccountId).subscribe({
        next: (result: any) => {
          let urlCreator = window.URL || window.webkitURL;
          this.imagenPerfil = urlCreator.createObjectURL(result);
        }, error: (error) => {
          this.imagenPerfil = null;
        }
      });
    }
  }

  async logout() {
    const currentAccount = this.msalService.instance.getAccountByHomeId(this.usuario!.homeAccountId);
    await this.msalService.instance.logoutRedirect({account: currentAccount})
  }

  public toogleOpcionesPerfil(): void {
    const opcionesPerfil = document.getElementById('opciones-perfil');
    opcionesPerfil?.classList.toggle('hidden');
  }

  private obtenerMenu(): void {
    this.obtenerMenuService.__invoke().subscribe({
      next: (elementos) => {
        this.elementosMenu = elementos;
      }
    });
  }

  public obtenerIcono(icono: string): SafeHtml {
    let iconoHTML = '';
    switch (icono) {
      case 'usuarios':
        iconoHTML = '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">\n' +
          '  <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />\n' +
          '</svg>';
        break;
      default:
        return '';
    }

    return this.sanitizer.bypassSecurityTrustHtml(iconoHTML);
  }

  public volverAlHome(): void {
    this.router.navigate(['/inicio']);
  }

  public toogleConversaciones(): void {
    this.menuAbierto = !this.menuAbierto;
    const conversaciones = document.getElementById('conversaciones');
    const overlay = document.getElementById('overlay');
    overlay?.classList.toggle('hidden');
    conversaciones?.classList.toggle('hidden');
  }

  public rutaVisible(elementoMenu: ElementoMenu): boolean {
    elementoMenu.activo = elementoMenu.ruta === this.rutaActual
    return elementoMenu.activo
  }

  public abrirAyuda(): void {
    const preview = this.panelLateralService.loadComponent(AyudaComponent);
    this.panelLateralService.setTitulo('Centro de ayuda');

    preview.instance.rutaWordpress = this.rutaWordpress;
  }

  protected rutaActualTieneAyuda(): boolean {
    const ruta = `/${this.rutaActual.split('/')[1]}`;
    this.rutaWordpress = this.rutasWordpress.find(r => r.rutaAngular === ruta)?.rutaWordpress;
    return this.rutasWordpress.some(r => r.rutaAngular === ruta);

  }

  private obtenerRutasAyuda() {
    this.obtenerAyudaService.__invoke().subscribe({
      next: (rutas) => {
        this.rutasWordpress = rutas;
      }
    });
  }

  private obtenerTooltip() {
    this.obtenerTooltipService.__invoke().subscribe({
      next: (mostrarTooltip) => {
        this.mostrarTooltip = mostrarTooltip
      }
    });
  }

  protected marcarTooltip(marcado: boolean) {
    if (marcado) {
      this.marcarTooltipService.__invoke().subscribe({
        next: () => {
          this.obtenerTooltip()
        }
      })
    }
  }
}
