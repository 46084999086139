import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {Observable} from 'rxjs';
import {ConversacionListado} from '../../interfaces/conversacion';
import {BuscarConversacionesFG} from './conversaciones.component';

@Injectable({
  providedIn: 'root'
})
export class BuscarConversacionesService {

  private readonly conversacionUrl: string;

  constructor(private httpClient: HttpClient) {
    this.conversacionUrl = environment.apiUrl + '/shared/conversacion/buscar';
  }

  public __invoke(parametros: BuscarConversacionesFG): Observable<ConversacionListado[]> {
    return this.httpClient.post<ConversacionListado[]>(this.conversacionUrl, parametros);
  }
}
