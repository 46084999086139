import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalConfirmacionAccionComponent} from './modal-confirmacion-accion.component';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [
        ModalConfirmacionAccionComponent
    ],
    exports: [
        ModalConfirmacionAccionComponent
    ],
  imports: [
    CommonModule,
    TranslateModule
  ]
})
export class ModalConfirmacionAccionModule { }
