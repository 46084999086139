import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalEditarConversacionComponent} from './modal-editar-conversacion.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingButtonDirective} from '../../../../infrastructure/directives/loading-button.directive';
import {CustomSelectModule} from '../custom-select/custom-select.module';


@NgModule({
  declarations: [
    ModalEditarConversacionComponent
  ],
  exports: [
    ModalEditarConversacionComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    LoadingButtonDirective,
    CustomSelectModule
  ]
})
export class ModalEditarConversacionModule { }
