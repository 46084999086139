import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ObtenerTooltipService {
  private readonly sharedUrl: string;

  constructor(private httpclient: HttpClient) {
    this.sharedUrl = environment.apiUrl + '/shared/tooltip';
  }

  public __invoke(): Observable<boolean> {
    return this.httpclient.get<boolean>(this.sharedUrl);
  }
}
