import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpEvent
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  private readonly apiKey: string | null;

  constructor(private inj: Injector, private router: Router) {
    this.apiKey = localStorage.getItem('apiKey');
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldIntercept(req.url)) {
      // Aplica la lógica del interceptor solo para rutas que comienzan con '/externas'
      const modifiedReq = this.addApiKey(req);
      return next.handle(modifiedReq).pipe(
        catchError(error => this.handleError(error))
      );
    } else {
      // Pasa la solicitud sin modificar para otras rutas
      return next.handle(req);
    }
  }

  private shouldIntercept(url: string): boolean {
    // Verifica si la URL comienza con '/externas' o contiene '/externas' después del dominio
    const pattern = /\/externas(\/|$)/;
    return pattern.test(url);
  }

  private addApiKey(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({ setHeaders: { 'CHAT-API-KEY': this.apiKey ?? '' } });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 400:
        case 401:
        case 403:
        case 405:
          this.goToLogin();
          break;
        default:
          // Maneja otros errores si es necesario
          break;
      }
    }
    return throwError(() => error);
  }

  private goToLogin(): void {
    sessionStorage.clear();
    localStorage.clear();
    // Puedes redireccionar al login si es necesario
    // this.router.navigate(['/login']);
  }
}
