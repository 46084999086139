import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {usuarioAzureActivoGuard} from './infrastructure/guards/usuario-azure-activo.guard';
import {PantallaConMenuComponent} from './infrastructure/layout/pantalla-con-menu/pantalla-con-menu.component';
import {PantallaCompletaComponent} from './infrastructure/layout/pantalla-completa/pantalla-completa.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login',
  },
  {
    path: '',
    component: PantallaConMenuComponent,
    children: [
      {
        path: 'usuarios',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/permisos/permisos.module').then(m => m.PermisosModule)
      },
      {
        path: 'perfiles-conversacion',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/perfiles-conversacion/perfiles-conversacion.module').then(m => m.PerfilesConversacionModule)
      },
      {
        path: 'grupos',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/grupos/grupos.module').then(m => m.GruposModule)
      },
      {
        path: 'colecciones-documentos',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/colecciones-documentos/colecciones-documentos.module').then(m => m.ColeccionesDocumentosModule)
      },
      {
        path: 'inicio',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/shared/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'chat',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/shared/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'configuracion',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/configuracion/configuracion.module').then(m => m.ConfiguracionModule)
      },
      {
        path: 'consumo-mensual',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/consumos-mensuales/consumos-mensuales.module').then(m => m.ConsumosMensualesModule)
      },
      {
        path: 'dashboard',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'documentos',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/documentos/documentos.module').then(m => m.DocumentosModule)
      },
      {
        path: 'conexiones-sql',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/conexiones-sql/conexiones-sql.module').then(m => m.ConexionesSqlModule)
      },
      {
        path: 'credenciales-conexion',
        canActivate: [usuarioAzureActivoGuard],
        loadChildren: () => import('./domain/administrador/credenciales-conexion/credenciales-conexion.module').then(m => m.CredencialesConexionModule)
      },
    ]
  },
  {
    component: PantallaCompletaComponent,
    path: 'login',
    loadChildren: () => import('./domain/public/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'webcomponents',
    canActivate: [],
    loadChildren: () => import('./domain/externas/webcomponents/webcomponents.module').then(m => m.WebcomponentsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
