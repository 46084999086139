import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ModalCrearConversacionComponent} from './modal-crear-conversacion.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {LoadingButtonDirective} from '../../../../infrastructure/directives/loading-button.directive';
import {CustomSelectModule} from '../custom-select/custom-select.module';


@NgModule({
  declarations: [
    ModalCrearConversacionComponent,
  ],
  exports: [
    ModalCrearConversacionComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        LoadingButtonDirective,
        CustomSelectModule
    ]
})
export class ModalCrearConversacionModule { }
